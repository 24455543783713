import { render, staticRenderFns } from "./PontoDePartida.vue?vue&type=template&id=4a1f6193&scoped=true&shadow"
import script from "./PontoDePartida.vue?vue&type=script&lang=js&shadow"
export * from "./PontoDePartida.vue?vue&type=script&lang=js&shadow"
function injectStyles (context) {
  
  var style0 = require("./PontoDePartida.vue?vue&type=style&index=0&id=4a1f6193&lang=scss&scoped=true&shadow")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4a1f6193",
  null
  ,true
)

export default component.exports