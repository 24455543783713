<template>
  <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M4.90958 0.363526L5.74276 1.19671L2.44549 4.49989L12.0005 4.49989L12.0005 5.68171L2.44549 5.68171L5.74276 8.98489L4.90958 9.81807L0.182307 5.0908L4.90958 0.363526Z"
        :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrowLeft",
  props: {
    color: {
      default: '#00AEEF'
    }
  }
}
</script>

<style scoped>

</style>
