<template>
  <div
      class="ponto-de-partida"
      data-testid="pontoDePartidaRoot"
      :style="colorStyle"
  >
    <div class="ponto-de-partida__tabs" v-if="!isMobile">
      <a
          class='ponto-de-partida-tab'
          v-for="(item, index) in items"
          :key="item.label"
          :class="{'ponto-de-partida-tab--selected': index === current}"
          @click='select(index)'
      >
        <span class="ponto-de-partida-tab__circle"></span> <span class="ponto-de-partida-tab__label" v-html="item.label"></span>
      </a>
    </div>

    <div class="ponto-de-partida__tabs" v-if="isMobile">
      <transition
          :name="direction"
      >
      <a
          :key="currentItem.label"
          class='ponto-de-partida-tab ponto-de-partida-tab--selected'
      >
        <span class="ponto-de-partida-tab__circle"></span> <span class="ponto-de-partida-tab__label" v-html="currentItem.label"></span>
      </a>
      </transition>
    </div>

    <div class="ponto-de-partida__content-wrapper">

      <div class="ponto-de-partida-arrows">
        <div
            class="ponto-de-partida-arrows__left"
            data-testid="arrow-left"
            :class="{
              'ponto-de-partida-arrows__left--disabled': current === 0
            }"
            @click="prev()"
        >
          <IconArrowLeft
            :color="colorPositive"
          />
        </div>
        <div
            class="ponto-de-partida-arrows__right"
            data-testid="arrow-right"
            :class="{
              'ponto-de-partida-arrows__right--disabled': current === this.items.length - 1
            }"
            @click="next()"
        >
          <IconArrowRight
              :color="colorPositive"
          />
        </div>
      </div>

      <transition
          :name="direction"
      >
        <div
            :key="currentItem.label"
            class="ponto-de-partida__content"
            data-testid="pontoDePartidaTransition"
        >
          <img
              class="ponto-de-partida__icon"
              :src="currentItem.icon"
              :alt="currentItem.label"
          /> {{ currentItem.content }}
        </div>
      </transition>

    </div>

  </div>
</template>

<script>
import computerIcon from './computer-icon.svg';
import rocketIcon from './rocket-icon.svg';
import peopleIcon from './people-icon.svg';
import peopleIcon2 from './people-2-icon.svg';
import IconArrowLeft from "./IconArrowLeft";
import IconArrowRight from "./IconArrowRight";

export default {
  name: 'ponto-de-partida',
  components: {IconArrowRight, IconArrowLeft},
  props: {
    data: {
      default: () => [
        {
          label: 'Nossas origens',
          icon: computerIcon,
          content: 'O Instituto Localiza nasce em 2021, ancorado no valor da Localiza de ser uma empresa cidadã e de sua trajetória de buscar gerar valor para a sociedade além do viés econômico entregue por seu próprio negócio. Para elevar sua atuação social ao patamar de investimento estratégico, cria o Instituto, dedicado a contribuir com o desenvolvimento social em locais onde a Localiza atua.'
        },
        {
          label: 'Escuta social',
          icon: rocketIcon,
          content: 'Absorvemos crenças importantes dos fundadores da Localiza: o acesso à educação e o fomento ao potencial empreendedor das pessoas contribuem decisivamente para o crescimento, autonomia e qualidade de vida das pessoas.'
        },
        {
          label: 'Dados sociais',
          icon: peopleIcon,
          content: 'Ouvimos lideranças de organizações sociais atuantes no combate à vulnerabilidade social e aprendemos sobre a importância de construir estratégias a partir de uma escuta cuidadosa e sempre com as pessoas com as quais queremos construir novos caminhos. Nos dedicamos a conhecer os dados sociais de nosso país para entender melhor os desafios que estão postos.'
        },
        {
          label: 'Ecossistema Localiza',
          icon: peopleIcon2,
          content: 'Estudamos o ecossistema no qual o negócio da Localiza está inserido, buscando identificar oportunidades sinérgicas a iniciativas para o desenvolvimento social de comunidades.'
        },
        {
          label: 'Investimento social',
          icon: peopleIcon2,
          content: 'Construímos assim a nossa proposta de atuação, com a certeza de que será um caminho de aprendizado e construção coletiva. Isso nos move.'
        },
      ]
    },
    colorPositive: {
      default: () => '#00984A'
    },
    colorNegative: {
      default: () => '#FFFFFF'
    }
  },
  data() {
    return {
      current: 0,
      forwards: true,
      isMobile: false
    }
  },
  created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize(){
      const mobileBreakpoint = 768;
      const width = window.innerWidth;

      this.isMobile = width <= mobileBreakpoint;
    },
    next(){
      if(this.current < this.items.length - 1){
        this.forwards = true;
        this.current++;
      }
    },
    prev(){
      if(this.current > 0){
        this.forwards = false;
        this.current--;
      }
    },
    select: function (index) {
      if (this.current < index) {
        this.forwards = false;
      } else if (this.current > index) {
        this.forwards = true;
      }
      this.current = index;
    }
  },
  computed: {
    items(){
      return typeof this.data === 'string' ? JSON.parse(this.data) : this.data;
    },
    currentItem() {
      return this.items.find((item, index) => index === this.current);
    },
    direction() {
      return this.forwards ? 'page' : 'page-back';
    },
    colorStyle(){
      return {
        '--color-positive': this.colorPositive,
        '--color-negative': this.colorNegative,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ponto-de-partida-arrows{
  display: none;
  position: absolute;
  z-index: 11;

  &__left, &__right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: var(--color-negative);
    border-radius: 50%;
    cursor: pointer;

    &--disabled{
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &__left{

  }

  &__right{

  }

  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 205px;
  }
}

.ponto-de-partida {
  &__content-wrapper{
    position: relative;
  }

  &__content {
    color: var(--color-negative);
    font-size: 22px;
    font-weight: 400;
    line-height: 1.9rem;
    padding: 24px;
    display: flex;
    align-items: center;
    text-align: left;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__icon{
    border-radius: 50%;
    background-color: var(--color-negative);
    width: 157px;
    height: 157px;
    margin-right: 24px;
    display: inline-block;

    @media only screen and (max-width: 768px) {
      margin-bottom: 24px;
      margin-right: 0;
    }
  }

  &__tabs {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
  }
}

.ponto-de-partida-tab {
  display: flex;
  flex-shrink: 0;
  margin-bottom: 5px;
  align-items: center;
  cursor: pointer;
  border-radius: 40px;
  padding: 4px 22px 4px 11px;
  color: var(--color-negative);
  font-size: 24px;
  margin-right: 24px;
  transition: all 0.3s;
  line-height: 2rem;

  &__circle {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 50%;
    background-color: var(--color-negative);
  }

  &__label{
  }

  &--selected, &:hover {
    background-color: var(--color-negative);
    color: var(--color-positive);

    .ponto-de-partida-tab__circle {
      background-color: var(--color-positive);
    }
  }

}

// Vue <transition> classes for animation

$percentage: 40%;

// Forwards transition

.page-enter {
  opacity: 0;
  transform: translateX($percentage);
}

.page-enter-active {
  transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.page-leave-active {
  display: none;
}

// Backwards transition

.page-back-enter {
  opacity: 0;
  transform: translateX(-$percentage);
}

.page-back-enter-active {
  transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.page-back-leave-active {
  display: none;
}
</style>
