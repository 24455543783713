<template>
  <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M7.0914 0.363526L6.25822 1.19671L9.55549 4.49989L0.00048792 4.49989L0.000487816 5.68171L9.55549 5.68171L6.25822 8.98489L7.0914 9.81807L11.8187 5.0908L7.0914 0.363526Z"
        :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrowRight",
  props: {
    color: {
      default: '#00AEEF'
    }
  }
}
</script>

<style scoped>

</style>
